export const countries = [
    { phone: "376", code: "AD", label: "Andorra", validStartDigits: [] },
    {
      phone: "971",
      code: "AE",
      label: "United Arab Emirates",
      validStartDigits: ["5"],
    },
    {
      phone: "93",
      code: "AF",
      label: "Afghanistan",
      validStartDigits: ["7", "8"],
    },
    {
      phone: "1",
      code: "AG",
      label: "Antigua and Barbuda",
      validStartDigits: ["2", "4"],
    },
    { phone: "1", code: "AI", label: "Anguilla", validStartDigits: ["2"] },
    {
      phone: "355",
      code: "AL",
      label: "Albania",
      validStartDigits: ["6", "7", "8", "9"],
    },
    { phone: "374", code: "AM", label: "Armenia", validStartDigits: ["5"] },
    { phone: "244", code: "AO", label: "Angola", validStartDigits: ["9"] },
    { phone: "672", code: "AQ", label: "Antarctica", validStartDigits: [] },
    {
      phone: "54",
      code: "AR",
      label: "Argentina",
      validStartDigits: ["2", "3", "4", "9"],
    },
    { phone: "1", code: "AS", label: "American Samoa", validStartDigits: ["6"] },
    {
      phone: "43",
      code: "AT",
      label: "Austria",
      validStartDigits: ["6", "7", "8", "9"],
    },
    { phone: "61", code: "AU", label: "Australia", validStartDigits: ["4"] },
    { phone: "297", code: "AW", label: "Aruba", validStartDigits: ["5"] },
    { phone: "358", code: "AX", label: "Åland Islands", validStartDigits: [] },
    { phone: "994", code: "AZ", label: "Azerbaijan", validStartDigits: ["5"] },
    {
      phone: "387",
      code: "BA",
      label: "Bosnia and Herzegovina",
      validStartDigits: ["6"],
    },
    { phone: "1", code: "BB", label: "Barbados", validStartDigits: ["2", "4"] },
    {
      phone: "880",
      code: "BD",
      label: "Bangladesh",
      validStartDigits: ["1", "6", "7", "8", "9"],
    },
    {
      phone: "32",
      code: "BE",
      label: "Belgium",
      validStartDigits: ["4", "6", "7"],
    },
    { phone: "226", code: "BF", label: "Burkina Faso", validStartDigits: ["7"] },
    { phone: "359", code: "BG", label: "Bulgaria", validStartDigits: ["8", "9"] },
    {
      phone: "973",
      code: "BH",
      label: "Bahrain",
      validStartDigits: ["3", "6", "7"],
    },
    { phone: "257", code: "BI", label: "Burundi", validStartDigits: ["7"] },
    { phone: "229", code: "BJ", label: "Benin", validStartDigits: ["9"] },
    {
      phone: "590",
      code: "BL",
      label: "Saint Barthélemy",
      validStartDigits: ["5"],
    },
    { phone: "1", code: "BM", label: "Bermuda", validStartDigits: ["2", "5"] },
    {
      phone: "673",
      code: "BN",
      label: "Brunei Darussalam",
      validStartDigits: ["8"],
    },
    { phone: "591", code: "BO", label: "Bolivia", validStartDigits: ["6"] },
    {
      phone: "55",
      code: "BR",
      label: "Brazil",
      validStartDigits: ["6", "7", "9"],
    },
    { phone: "1", code: "BS", label: "Bahamas", validStartDigits: ["2", "4"] },
    { phone: "975", code: "BT", label: "Bhutan", validStartDigits: ["2", "7"] },
    { phone: "47", code: "BV", label: "Bouvet Island", validStartDigits: [] },
    { phone: "267", code: "BW", label: "Botswana", validStartDigits: ["7"] },
    {
      phone: "375",
      code: "BY",
      label: "Belarus",
      validStartDigits: ["2", "6", "7", "8"],
    },
    { phone: "501", code: "BZ", label: "Belize", validStartDigits: ["6"] },
    {
      phone: "1",
      code: "CA",
      label: "Canada",
      validStartDigits: ["4", "5", "6", "7", "8", "9"],
    },
    {
      phone: "61",
      code: "CC",
      label: "Cocos (Keeling) Islands",
      validStartDigits: [],
    },
    {
      phone: "243",
      code: "CD",
      label: "Congo, Democratic Republic of the",
      validStartDigits: ["9"],
    },
    {
      phone: "236",
      code: "CF",
      label: "Central African Republic",
      validStartDigits: ["7"],
    },
    {
      phone: "242",
      code: "CG",
      label: "Congo, Republic of the",
      validStartDigits: ["7"],
    },
    { phone: "41", code: "CH", label: "Switzerland", validStartDigits: ["7"] },
    {
      phone: "225",
      code: "CI",
      label: "Côte d'Ivoire",
      validStartDigits: ["0", "2"],
    },
    { phone: "682", code: "CK", label: "Cook Islands", validStartDigits: [] },
    { phone: "56", code: "CL", label: "Chile", validStartDigits: ["9"] },
    {
      phone: "237",
      code: "CM",
      label: "Cameroon",
      validStartDigits: ["6", "7", "8"],
    },
    { phone: "86", code: "CN", label: "China", validStartDigits: ["1"] },
    { phone: "57", code: "CO", label: "Colombia", validStartDigits: ["3", "5"] },
    { phone: "506", code: "CR", label: "Costa Rica", validStartDigits: ["6"] },
    { phone: "53", code: "CU", label: "Cuba", validStartDigits: ["5"] },
    { phone: "238", code: "CV", label: "Cape Verde", validStartDigits: ["9"] },
    { phone: "599", code: "CW", label: "Curaçao", validStartDigits: ["9"] },
    { phone: "61", code: "CX", label: "Christmas Island", validStartDigits: [] },
    { phone: "357", code: "CY", label: "Cyprus", validStartDigits: ["9"] },
    {
      phone: "420",
      code: "CZ",
      label: "Czech Republic",
      validStartDigits: ["6", "7", "8"],
    },
    { phone: "49", code: "DE", label: "Germany", validStartDigits: ["1"] },
    { phone: "253", code: "DJ", label: "Djibouti", validStartDigits: ["7"] },
    { phone: "45", code: "DK", label: "Denmark", validStartDigits: ["2", "4"] },
    { phone: "1", code: "DM", label: "Dominica", validStartDigits: ["6"] },
    {
      phone: "1",
      code: "DO",
      label: "Dominican Republic",
      validStartDigits: ["6"],
    },
    { phone: "213", code: "DZ", label: "Algeria", validStartDigits: ["5"] },
    { phone: "593", code: "EC", label: "Ecuador", validStartDigits: ["9"] },
    { phone: "372", code: "EE", label: "Estonia", validStartDigits: ["5"] },
    { phone: "20", code: "EG", label: "Egypt", validStartDigits: ["1", "7"] },
    { phone: "212", code: "EH", label: "Western Sahara", validStartDigits: [] },
    { phone: "291", code: "ER", label: "Eritrea", validStartDigits: ["7"] },
    { phone: "34", code: "ES", label: "Spain", validStartDigits: ["6"] },
    { phone: "251", code: "ET", label: "Ethiopia", validStartDigits: ["9"] },
    { phone: "358", code: "FI", label: "Finland", validStartDigits: ["4"] },
    { phone: "679", code: "FJ", label: "Fiji", validStartDigits: ["7"] },
    {
      phone: "500",
      code: "FK",
      label: "Falkland Islands (Malvinas)",
      validStartDigits: [],
    },
    {
      phone: "691",
      code: "FM",
      label: "Micronesia, Federated States of",
      validStartDigits: ["3", "4", "5", "6", "7", "8"],
    },
    { phone: "33", code: "FR", label: "France", validStartDigits: ["6"] },
    { phone: "241", code: "GA", label: "Gabon", validStartDigits: ["7"] },
    { phone: "220", code: "GB", label: "Gambia", validStartDigits: ["3", "7"] },
    { phone: "995", code: "GE", label: "Georgia", validStartDigits: ["5"] },
    { phone: "49", code: "DE", label: "Germany", validStartDigits: ["1"] },
    {
      phone: "233",
      code: "GH",
      label: "Ghana",
      validStartDigits: ["2", "5", "6", "7", "9"],
    },
    { phone: "350", code: "GI", label: "Gibraltar", validStartDigits: [] },
    { phone: "299", code: "GL", label: "Greenland", validStartDigits: ["5"] },
    { phone: "1", code: "GM", label: "Gambia", validStartDigits: ["7"] },
    { phone: "220", code: "GN", label: "Guinea", validStartDigits: ["6"] },
    {
      phone: "502",
      code: "GT",
      label: "Guatemala",
      validStartDigits: ["4", "5", "7", "9"],
    },
    { phone: "44", code: "GU", label: "Guam", validStartDigits: ["6"] },
    { phone: "224", code: "GN", label: "Guinea", validStartDigits: [] },
    {
      phone: "592",
      code: "GY",
      label: "Guyana",
      validStartDigits: ["6", "7", "9"],
    },
    { phone: "504", code: "HN", label: "Honduras", validStartDigits: ["9"] },
    {
      phone: "36",
      code: "HU",
      label: "Hungary",
      validStartDigits: ["20", "30", "70"],
    },
    { phone: "354", code: "IS", label: "Iceland", validStartDigits: ["8"] },
    {
      phone: "91",
      code: "IN",
      label: "India",
      validStartDigits: ["6", "7", "8", "9"],
    },
    { phone: "62", code: "ID", label: "Indonesia", validStartDigits: ["8"] },
    { phone: "98", code: "IR", label: "Iran", validStartDigits: ["9"] },
    { phone: "353", code: "IE", label: "Ireland", validStartDigits: ["8"] },
    { phone: "972", code: "IL", label: "Israel", validStartDigits: ["5"] },
    { phone: "39", code: "IT", label: "Italy", validStartDigits: ["3"] },
    { phone: "1", code: "JM", label: "Jamaica", validStartDigits: ["3", "5"] },
    { phone: "81", code: "JP", label: "Japan", validStartDigits: ["9"] },
    { phone: "962", code: "JO", label: "Jordan", validStartDigits: ["7"] },
    { phone: "7", code: "KZ", label: "Kazakhstan", validStartDigits: ["7"] },
    { phone: "254", code: "KE", label: "Kenya", validStartDigits: ["7"] },
    { phone: "996", code: "KG", label: "Kyrgyzstan", validStartDigits: ["7"] },
    { phone: "686", code: "KI", label: "Kiribati", validStartDigits: [] },
    { phone: "383", code: "XK", label: "Kosovo", validStartDigits: [] },
    { phone: "855", code: "KH", label: "Cambodia", validStartDigits: ["9"] },
    { phone: "686", code: "KI", label: "Kiribati", validStartDigits: [] },
    { phone: "45", code: "DK", label: "Denmark", validStartDigits: ["2", "4"] },
    {
      phone: "965",
      code: "KW",
      label: "Kuwait",
      validStartDigits: ["5", "6", "7"],
    },
    { phone: "996", code: "KG", label: "Kyrgyzstan", validStartDigits: ["7"] },
    {
      phone: "371",
      code: "LV",
      label: "Latvia",
      validStartDigits: ["2", "6", "7", "8"],
    },
    { phone: "961", code: "LB", label: "Lebanon", validStartDigits: ["3", "7"] },
    { phone: "266", code: "LS", label: "Lesotho", validStartDigits: ["5"] },
    { phone: "231", code: "LR", label: "Liberia", validStartDigits: ["7"] },
    { phone: "218", code: "LY", label: "Libya", validStartDigits: ["5"] },
    {
      phone: "423",
      code: "LI",
      label: "Liechtenstein",
      validStartDigits: ["6", "7"],
    },
    {
      phone: "370",
      code: "LT",
      label: "Lithuania",
      validStartDigits: ["6", "7", "8"],
    },
    { phone: "352", code: "LU", label: "Luxembourg", validStartDigits: ["6"] },
    { phone: "853", code: "MO", label: "Macao", validStartDigits: ["6"] },
    {
      phone: "389",
      code: "MK",
      label: "North Macedonia",
      validStartDigits: ["7"],
    },
    {
      phone: "261",
      code: "MG",
      label: "Madagascar",
      validStartDigits: ["3", "4", "5", "6", "7"],
    },
    { phone: "265", code: "MW", label: "Malawi", validStartDigits: ["9"] },
    {
      phone: "60",
      code: "MY",
      label: "Malaysia",
      validStartDigits: ["1", "3", "4", "5", "6", "7", "8", "9"],
    },
    { phone: "960", code: "MV", label: "Maldives", validStartDigits: ["7"] },
    { phone: "223", code: "ML", label: "Mali", validStartDigits: ["7"] },
    { phone: "356", code: "MT", label: "Malta", validStartDigits: ["7"] },
    { phone: "596", code: "MQ", label: "Martinique", validStartDigits: ["6"] },
    { phone: "222", code: "MR", label: "Mauritania", validStartDigits: ["7"] },
    { phone: "230", code: "MU", label: "Mauritius", validStartDigits: ["5"] },
    {
      phone: "52",
      code: "MX",
      label: "Mexico",
      validStartDigits: ["1", "2", "3", "4", "5", "6", "7", "8", "9"],
    },
    {
      phone: "691",
      code: "FM",
      label: "Micronesia, Federated States of",
      validStartDigits: [],
    },
    {
      phone: "373",
      code: "MD",
      label: "Moldova",
      validStartDigits: ["6", "7", "8"],
    },
    { phone: "977", code: "NP", label: "Nepal", validStartDigits: ["7"] },
    {
      phone: "31",
      code: "NL",
      label: "Netherlands",
      validStartDigits: ["6", "7", "8"],
    },
    { phone: "64", code: "NZ", label: "New Zealand", validStartDigits: ["2"] },
    { phone: "505", code: "NI", label: "Nicaragua", validStartDigits: ["8"] },
    { phone: "227", code: "NE", label: "Niger", validStartDigits: ["6", "9"] },
    { phone: "234", code: "NG", label: "Nigeria", validStartDigits: ["7"] },
    { phone: "672", code: "NF", label: "Norfolk Island", validStartDigits: [] },
    { phone: "683", code: "NU", label: "Niue", validStartDigits: [] },
    {
      phone: "1",
      code: "MP",
      label: "Northern Mariana Islands",
      validStartDigits: ["6"],
    },
    { phone: "47", code: "NO", label: "Norway", validStartDigits: ["4"] },
    { phone: "968", code: "OM", label: "Oman", validStartDigits: ["9"] },
    {
      phone: "92",
      code: "PK",
      label: "Pakistan",
      validStartDigits: ["3", "6", "7", "8", "9"],
    },
    { phone: "680", code: "PW", label: "Palau", validStartDigits: [] },
    {
      phone: "970",
      code: "PS",
      label: "Palestine",
      validStartDigits: ["5", "6"],
    },
    {
      phone: "507",
      code: "PA",
      label: "Panama",
      validStartDigits: ["6", "7", "8"],
    },
    {
      phone: "675",
      code: "PG",
      label: "Papua New Guinea",
      validStartDigits: ["7"],
    },
    { phone: "51", code: "PE", label: "Peru", validStartDigits: ["9"] },
    { phone: "63", code: "PH", label: "Philippines", validStartDigits: ["9"] },
    { phone: "48", code: "PL", label: "Poland", validStartDigits: ["5"] },
    { phone: "351", code: "PT", label: "Portugal", validStartDigits: ["9"] },
    { phone: "40", code: "RO", label: "Romania", validStartDigits: ["7"] },
    { phone: "7", code: "RU", label: "Russia", validStartDigits: ["9"] },
    { phone: "250", code: "RW", label: "Rwanda", validStartDigits: ["7"] },
    {
      phone: "1",
      code: "BL",
      label: "Saint Barthélemy",
      validStartDigits: ["5"],
    },
    { phone: "590", code: "MF", label: "Saint Martin", validStartDigits: ["6"] },
    {
      phone: "1",
      code: "PM",
      label: "Saint Pierre and Miquelon",
      validStartDigits: ["5"],
    },
    {
      phone: "1",
      code: "VC",
      label: "Saint Vincent and the Grenadines",
      validStartDigits: ["4"],
    },
    {
      phone: "508",
      code: "PM",
      label: "Saint Pierre and Miquelon",
      validStartDigits: ["5"],
    },
    { phone: "685", code: "WS", label: "Samoa", validStartDigits: [] },
    { phone: "378", code: "SM", label: "San Marino", validStartDigits: ["5"] },
    {
      phone: "239",
      code: "ST",
      label: "Sao Tome and Principe",
      validStartDigits: [],
    },
    { phone: "966", code: "SA", label: "Saudi Arabia", validStartDigits: ["5"] },
    { phone: "221", code: "SN", label: "Senegal", validStartDigits: ["7"] },
    { phone: "386", code: "SI", label: "Slovenia", validStartDigits: ["4", "6"] },
    { phone: "65", code: "SG", label: "Singapore", validStartDigits: ["8"] },
    { phone: "421", code: "SK", label: "Slovakia", validStartDigits: ["9"] },
    { phone: "963", code: "SY", label: "Syria", validStartDigits: ["9"] },
    { phone: "886", code: "TW", label: "Taiwan", validStartDigits: ["9"] },
    { phone: "66", code: "TH", label: "Thailand", validStartDigits: ["8"] },
    { phone: "228", code: "TG", label: "Togo", validStartDigits: ["9"] },
    { phone: "676", code: "TO", label: "Tonga", validStartDigits: [] },
    {
      phone: "1",
      code: "TT",
      label: "Trinidad and Tobago",
      validStartDigits: ["6", "7"],
    },
    { phone: "256", code: "UG", label: "Uganda", validStartDigits: ["7"] },
    {
      phone: "380",
      code: "UA",
      label: "Ukraine",
      validStartDigits: ["5", "6", "7", "9"],
    },
    {
      phone: "971",
      code: "AE",
      label: "United Arab Emirates",
      validStartDigits: ["5"],
    },
    { phone: "44", code: "GB", label: "United Kingdom", validStartDigits: ["7"] },
    {
      phone: "1",
      code: "USA",
      label: "United States Of America",
      validStartDigits: ["2", "3", "4", "5", "6", "7", "8", "9"],
    },
    { phone: "598", code: "UY", label: "Uruguay", validStartDigits: ["3"] },
    { phone: "998", code: "UZ", label: "Uzbekistan", validStartDigits: ["9"] },
    { phone: "678", code: "VU", label: "Vanuatu", validStartDigits: [] },
    { phone: "39", code: "VA", label: "Vatican City", validStartDigits: [] },
    { phone: "58", code: "VE", label: "Venezuela", validStartDigits: ["4"] },
    {
      phone: "84",
      code: "VN",
      label: "Vietnam",
      validStartDigits: ["3", "4", "5", "6", "7", "8"],
    },
    {
      phone: "1",
      code: "VG",
      label: "British Virgin Islands",
      validStartDigits: ["5"],
    },
    {
      phone: "1",
      code: "VI",
      label: "U.S. Virgin Islands",
      validStartDigits: ["5"],
    },
    {
      phone: "681",
      code: "WF",
      label: "Wallis and Futuna",
      validStartDigits: [],
    },
    { phone: "260", code: "ZM", label: "Zambia", validStartDigits: ["9"] },
    { phone: "263", code: "ZW", label: "Zimbabwe", validStartDigits: ["7"] },
  ];